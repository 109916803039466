<template>
  <div>
    <template v-if="isViewableInDocumentViewer">
      <!-- provided record-file is viewable in Questys document viewer -->
      <template v-if="canEditFileVersionInDocumentEditor">
        <!--
          Open provided record-file in Document editor
        -->
        <v-tooltip top v-bind="$attrs" v-on="$listeners">
          <template v-slot:activator="{ attrs, on }">
            <a
              v-bind="attrs"
              v-on="on"
              v-on:click="onOpenDocumentEditor"
              class="hyperlink"
              >{{ documentName }}</a
            >
          </template>
          {{ tooltipEdit }}
        </v-tooltip>
      </template>
      <template v-else>
        <!-- Cannot edit File Version In Document Editor -->
        <template v-if="openDefaultPdfViewer">
          <!--
            Open PDF document for viewing
          -->
          <v-tooltip top v-bind="$attrs" v-on="$listeners">
            <template v-slot:activator="{ attrs, on }">
              <a
                v-bind="attrs"
                v-on="on"
                v-on:click="onOpenDefaultPdfViewer"
                class="hyperlink"
                >{{ documentName }}</a
              >
            </template>
            {{ tooltipOpenPDFViewer }}
          </v-tooltip>
        </template>
        <template v-else>
          <!--
            Open an image file for viewing in Questys document viewer
          -->
          <!--          <div>-->
          <!--            REMARKS: it will course record name to be not shown, so the code (v-tooltip) is commented out    -->
          <!--            <span>Open the image file</span>-->
          <!--            <v-tooltip top v-bind="$attrs" v-on="$listeners">-->
          <!--              <template v-slot:activator="{ attrs, on }">-->
          <!--                <div v-bind="attrs" v-on="on">-->
          <router-link :to="documentViewerRoute" :target="target">
            {{ documentName }}</router-link
          >
          <!--                </div>-->
          <!--              </template>-->
          <!--              {{ tooltipView }}-->
          <!--            </v-tooltip>-->
          <!--          </div>-->
        </template>
      </template>
    </template>
    <template v-else>
      <!--
        provided record-file is not viewable In Questys document viewer, so just display document name
      -->
      <div>
        <span>{{ documentName }}</span>
      </div>
    </template>
  </div>
</template>

<script>
// services
import {
  fullRecordName,
  localFileExists
} from "@/services/record/recordService";

// model
import {
  canEditFileVersionInDocumentEditor,
  isViewableInDocumentViewer
} from "@/model/viewer/viewerFileModel";

// router
import {
  createDocumentViewerFileHistoryRoute,
  createDocumentViewerRoute
} from "@/router/document/documentRoutes";

// model
import { eventNames } from "@/model/common/events/eventConst";
import { isPdfExtension } from "@/model/record/fileModel";

// mixins
import { principalMixin } from "@/mixins/shared/user/principalMixin";

/**
 * Document Link component used to open document viewer/editor
 */
export default {
  name: "DocumentLink",
  mixins: [principalMixin],
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,
    fullTextSearchCriteria: {
      type: String,
      default: undefined
    },
    useDefaultPdfViewer: {
      type: Boolean,
      default: false
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * compute File name to be displayed
     * @return {string|string} File name
     */
    documentName() {
      return this.record ? fullRecordName(this.record) : "";
    },

    /**
     * Determines whether current record-file is viewable in Questys' Document Viewer
     * @return {boolean|Boolean} true if the record-file is viewable in Questys' Document Viewer
     */
    isViewableInDocumentViewer() {
      return isViewableInDocumentViewer(this.record);
    },

    /**
     * Get Questys Document viewer route
     * @return {{name: string, params: {id: number}, query: {searchTerm: string, documentVersion: number}}}}
     */
    documentViewerRoute() {
      return this.isHistory
        ? createDocumentViewerFileHistoryRoute(
            this.record,
            this.fullTextSearchCriteria
          )
        : createDocumentViewerRoute(this.record, this.fullTextSearchCriteria);
    },

    /**
     * router-link target
     * @return {string} router-link's target
     */
    target() {
      return "_blank";
    },

    /**
     * can Edit File Version In Document Editor
     * @return {(function(): boolean)|*|Boolean|boolean}
     */
    canEditFileVersionInDocumentEditor() {
      return (
        canEditFileVersionInDocumentEditor(this.record, this.principal) &&
        !this.isHistory
      );
    },

    /**
     * compute tooltip Open Document Editor
     * @return {string} tooltip Open Document Editor
     */
    tooltipEdit() {
      return `Open for editing`;
    },

    /**
     * compute tooltip Open Document Viewer
     * @return {string} tooltip Open Document Viewer
     */
    tooltipView() {
      return `Open for viewing`;
    },

    /**
     * tooltip Open PDF document for viewing
     * @return {string} tooltip
     */
    tooltipOpenPDFViewer() {
      return `Open PDF document for viewing`;
    },

    /**
     * Open Default Pdf Viewer
     * @return {Boolean}
     */
    openDefaultPdfViewer() {
      return this.isPdfDocument() && this.useDefaultPdfViewer;
    }
  },
  methods: {
    /**
     * Handles Open Document Editor
     */
    onOpenDocumentEditor() {
      this.$emit(eventNames.click, this.record);
    },

    /**
     * Handles Open Default Pdf Viewer
     */
    onOpenDefaultPdfViewer() {
      this.$emit(eventNames.openDefaultPdfViewer, this.record);
    },

    /**
     * compute If record is pdf Document
     * @return {boolean}
     */
    isPdfDocument() {
      return (
        isPdfExtension(this.record?.extension ?? "") &&
        (!localFileExists(this.record) || this.isHistory)
      );
    }
  }
};
</script>

<style scoped>
.hyperlink {
  text-decoration: underline;
  cursor: pointer;
}
</style>
